var __defProp = Object.defineProperty;
var __name = (target, value) => __defProp(target, "name", { value, configurable: true });
import { C as CodeMirror } from "./codemirror.es.js";
import { specifiedRules, NoUnusedFragmentsRule, ExecutableDefinitionsRule, KnownFragmentNamesRule, validate, Kind, LoneSchemaDefinitionRule, UniqueOperationTypesRule, UniqueTypeNamesRule, UniqueEnumValueNamesRule, UniqueFieldDefinitionNamesRule, UniqueDirectiveNamesRule, KnownTypeNamesRule, KnownDirectivesRule, UniqueDirectivesPerLocationRule, PossibleTypeExtensionsRule, UniqueArgumentNamesRule, UniqueInputFieldNamesRule, print, parse, GraphQLError, NoDeprecatedCustomRule } from "graphql";
import { C as CharacterStream, o as onlineParser } from "./index.es.js";
import { R as Range, P as Position } from "./Range.es.js";
import "react";
import "react-dom";
const specifiedSDLRules = [
  LoneSchemaDefinitionRule,
  UniqueOperationTypesRule,
  UniqueTypeNamesRule,
  UniqueEnumValueNamesRule,
  UniqueFieldDefinitionNamesRule,
  UniqueDirectiveNamesRule,
  KnownTypeNamesRule,
  KnownDirectivesRule,
  UniqueDirectivesPerLocationRule,
  PossibleTypeExtensionsRule,
  UniqueArgumentNamesRule,
  UniqueInputFieldNamesRule
];
function validateWithCustomRules(schema, ast, customRules, isRelayCompatMode, isSchemaDocument) {
  const rules = specifiedRules.filter((rule) => {
    if (rule === NoUnusedFragmentsRule || rule === ExecutableDefinitionsRule) {
      return false;
    }
    if (isRelayCompatMode && rule === KnownFragmentNamesRule) {
      return false;
    }
    return true;
  });
  if (customRules) {
    Array.prototype.push.apply(rules, customRules);
  }
  if (isSchemaDocument) {
    Array.prototype.push.apply(rules, specifiedSDLRules);
  }
  const errors = validate(schema, ast, rules);
  return errors.filter((error) => {
    if (error.message.includes("Unknown directive") && error.nodes) {
      const node = error.nodes[0];
      if (node && node.kind === Kind.DIRECTIVE) {
        const name = node.name.value;
        if (name === "arguments" || name === "argumentDefinitions") {
          return false;
        }
      }
    }
    return true;
  });
}
__name(validateWithCustomRules, "validateWithCustomRules");
const SEVERITY$1 = {
  Error: "Error",
  Warning: "Warning",
  Information: "Information",
  Hint: "Hint"
};
const DIAGNOSTIC_SEVERITY = {
  [SEVERITY$1.Error]: 1,
  [SEVERITY$1.Warning]: 2,
  [SEVERITY$1.Information]: 3,
  [SEVERITY$1.Hint]: 4
};
const invariant = /* @__PURE__ */ __name((condition, message) => {
  if (!condition) {
    throw new Error(message);
  }
}, "invariant");
function getDiagnostics(query, schema = null, customRules, isRelayCompatMode, externalFragments) {
  var _a, _b;
  let ast = null;
  let fragments = "";
  if (externalFragments) {
    fragments = typeof externalFragments === "string" ? externalFragments : externalFragments.reduce((acc, node) => acc + print(node) + "\n\n", "");
  }
  const enhancedQuery = fragments ? `${query}

${fragments}` : query;
  try {
    ast = parse(enhancedQuery);
  } catch (error) {
    if (error instanceof GraphQLError) {
      const range = getRange((_b = (_a = error.locations) === null || _a === void 0 ? void 0 : _a[0]) !== null && _b !== void 0 ? _b : { line: 0, column: 0 }, enhancedQuery);
      return [
        {
          severity: DIAGNOSTIC_SEVERITY.Error,
          message: error.message,
          source: "GraphQL: Syntax",
          range
        }
      ];
    }
    throw error;
  }
  return validateQuery(ast, schema, customRules, isRelayCompatMode);
}
__name(getDiagnostics, "getDiagnostics");
function validateQuery(ast, schema = null, customRules, isRelayCompatMode) {
  if (!schema) {
    return [];
  }
  const validationErrorAnnotations = validateWithCustomRules(schema, ast, customRules, isRelayCompatMode).flatMap((error) => annotations(error, DIAGNOSTIC_SEVERITY.Error, "Validation"));
  const deprecationWarningAnnotations = validate(schema, ast, [
    NoDeprecatedCustomRule
  ]).flatMap((error) => annotations(error, DIAGNOSTIC_SEVERITY.Warning, "Deprecation"));
  return validationErrorAnnotations.concat(deprecationWarningAnnotations);
}
__name(validateQuery, "validateQuery");
function annotations(error, severity, type) {
  if (!error.nodes) {
    return [];
  }
  const highlightedNodes = [];
  error.nodes.forEach((node, i) => {
    const highlightNode = node.kind !== "Variable" && "name" in node && node.name !== void 0 ? node.name : "variable" in node && node.variable !== void 0 ? node.variable : node;
    if (highlightNode) {
      invariant(error.locations, "GraphQL validation error requires locations.");
      const loc = error.locations[i];
      const highlightLoc = getLocation(highlightNode);
      const end = loc.column + (highlightLoc.end - highlightLoc.start);
      highlightedNodes.push({
        source: `GraphQL: ${type}`,
        message: error.message,
        severity,
        range: new Range(new Position(loc.line - 1, loc.column - 1), new Position(loc.line - 1, end))
      });
    }
  });
  return highlightedNodes;
}
__name(annotations, "annotations");
function getRange(location, queryText) {
  const parser = onlineParser();
  const state = parser.startState();
  const lines = queryText.split("\n");
  invariant(lines.length >= location.line, "Query text must have more lines than where the error happened");
  let stream = null;
  for (let i = 0; i < location.line; i++) {
    stream = new CharacterStream(lines[i]);
    while (!stream.eol()) {
      const style = parser.token(stream, state);
      if (style === "invalidchar") {
        break;
      }
    }
  }
  invariant(stream, "Expected Parser stream to be available.");
  const line = location.line - 1;
  const start = stream.getStartOfToken();
  const end = stream.getCurrentPosition();
  return new Range(new Position(line, start), new Position(line, end));
}
__name(getRange, "getRange");
function getLocation(node) {
  const typeCastedNode = node;
  const location = typeCastedNode.loc;
  invariant(location, "Expected ASTNode to have a location.");
  return location;
}
__name(getLocation, "getLocation");
const SEVERITY = ["error", "warning", "information", "hint"];
const TYPE = {
  "GraphQL: Validation": "validation",
  "GraphQL: Deprecation": "deprecation",
  "GraphQL: Syntax": "syntax"
};
CodeMirror.registerHelper("lint", "graphql", (text, options) => {
  const { schema, validationRules, externalFragments } = options;
  const rawResults = getDiagnostics(text, schema, validationRules, void 0, externalFragments);
  const results = rawResults.map((error) => ({
    message: error.message,
    severity: error.severity ? SEVERITY[error.severity - 1] : SEVERITY[0],
    type: error.source ? TYPE[error.source] : void 0,
    from: CodeMirror.Pos(error.range.start.line, error.range.start.character),
    to: CodeMirror.Pos(error.range.end.line, error.range.end.character)
  }));
  return results;
});
